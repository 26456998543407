<template>
  <VForm
    v-model="formData"
    class="column gap-s"
    @request="submitForm"
  >
    <ReclamationExternalSelectIndicator
      label="Индикатор"
      name="indicator"
      :options="indicatorOptions"
      class="max-w-input"
      :is-disabled="isDisabledIndicator"
      is-required
      :is-allow-empty="false"
    >
      <Validation for="required" />
    </ReclamationExternalSelectIndicator>

    <div class="form-grid--col-2">
      <VInput
        :label="ELabels.orderNumber"
        name="account_number"
        :disabled="isDisabledFields"
        required
      >
        <Validation for="required" />
      </VInput>

      <VInput
        :label="ELabels.disk"
        name="google_link"
        :disabled="isDisabledFields"
      />

      <VInput
        :label="ELabels.positionNumber"
        name="position_number"
        :pattern="PATTERN_LIST.num"
        :disabled="isDisabledFields"
      >
        <Validation for="pattern" key-text="num" />
      </VInput>

      <VInput
        :label="ELabels.dealAtCRM"
        name="crm_link"
        :disabled="isDisabledFields"
      />

      <VInput
        :label="ELabels.company"
        name="client_name"
        :disabled="isDisabledFields"
      />

      <VSelect
        :label="ELabels.responsibleManager"
        name="responsible"
        :options="$store.state.users.all"
        :disabled="isDisabledFields"
      />

      <VSelect
        :label="ELabels.reasonPetition"
        name="reclamation_reason_treatment"
        :options="reasonTreatmentOptions"
        :disabled="isDisabledFields"
      />
    </div>

    <ButtonGroup
      textBtnConfirm="Сохранить"
      textBtnReject="Отмена"
      btn-size="l"
      :loading="isLoading"
      @reject="$emit('cancel')"
    />
  </VForm>
</template>

<script>
import VForm from '@/components/Form/VForm.vue'
import VSelect from '@/components/Form/VSelect.vue'
import Validation from '@/components/Form/Validation.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup.vue'
import {
  ReclamationExternalSelectIndicator,
  reclamationExternalModel
} from '@/entities/reclamation-external'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { mapActions, mapMutations, mapState } from 'vuex'
import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { PATTERN_LIST } from '@/const/validation'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { goToPage } from '@/utils/router'
import { addIdTo, transformToNumbers } from '@/shared/lib/utils/form'

export default {
  components: {
    ButtonGroup,
    VInput,
    Validation,
    VSelect,
    ReclamationExternalSelectIndicator,
    VForm
  },
  mixins: [mixinIsLoading],

  props: {
    isCreate: {
      type: Boolean
    },
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal
    }
  },

  data: () => ({
    formData: {
      indicator: null,
      account_number: null,
      google_link: null,
      position_number: null,
      crm_link: null,
      client_name: null,
      responsible: null,
      reclamation_reason_treatment: null
    }
  }),

  computed: {
    ...mapState('handbook/reasonTreatment', {
      reasonTreatmentOptions: 'list'
    }),
    PATTERN_LIST: () => PATTERN_LIST,
    ELabels: () => reclamationExternalModel.ELabels,

    indicatorOptions () {
      if (this.isCreate) return reclamationExternalModel.INDICATORS.slice(0, 1)

      if (this.reclamation.hasPosition()) {
        return [
          ...reclamationExternalModel.INDICATORS.slice(1, 3),
          reclamationExternalModel.INDICATORS[4]
        ]
      }

      return reclamationExternalModel.INDICATORS.slice(1, 3)
    },

    isDisabledFields () {
      return !this.$store.getters['permission/permissionUpdateReclamationGeneralInformation']
    },

    isDisabledIndicator () {
      return this.isCreate || !this.$store.getters['permission/permissionUpdateReclamationIndicator'] || this.reclamation.hasResolution()
    }
  },

  created () {
    if (!this.reasonTreatmentOptions.length) {
      this.loadReasonTreatmentOptions()
    }

    if (this.isCreate) {
      this.formData.indicator = reclamationExternalModel.INDICATORS[0]
      return
    }

    this.syncFormData()
  },

  methods: {
    ...mapMutations('reclamationExternal/details', {
      UPDATE_GENERAL_INFORMATION: 'UPDATE_GENERAL_INFORMATION',
      UPDATE_RECLAMATION: 'UPDATE_RECLAMATION',
      UPDATE_STATUS_AND_INDICATOR: 'UPDATE_STATUS_AND_INDICATOR'
    }),

    ...mapActions('handbook/reasonTreatment', {
      loadReasonTreatmentOptions: 'loadList'
    }),

    async submitForm () {
      try {
        this.startLoading()
        const data = this.getMapped(this.formData)

        if (this.isCreate) {
          await this.createReclamation(data)
        } else {
          await this.updateReclamation(data)
        }
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    async createReclamation (data) {
      const response = await ReclamationApi.create(data)
      await goToPage(this.$store.state.pages.reclamationPages.details, { id: response.data.id })
    },

    async updateReclamation (data) {
      if (data.indicator === 5) {
        this.UPDATE_RECLAMATION(data)
        this.$emit('update-modal')
      } else {
        const response = await ReclamationApi.update(this.reclamation.id, data)
        this.UPDATE_GENERAL_INFORMATION(data)
        this.UPDATE_STATUS_AND_INDICATOR(response.data)
        showAlertSuccess('Общая информация обновлена')
      }
      this.$emit('update-success')
    },

    getMapped (formData) {
      const copy = {
        ...formData
      }

      addIdTo(copy, [
        'responsible',
        'reclamation_reason_treatment'
      ])

      transformToNumbers(copy, ['position_number'])

      return {
        ...copy,
        indicator: formData.indicator.id
      }
    },

    syncFormData () {
      for (const key in this.formData) {
        if (!this.reclamation[key]) continue

        if (key === 'indicator') {
          this.formData.indicator = reclamationExternalModel.INDICATORS[this.reclamation.indicator - 1]
          continue
        }

        this.formData[key] = this.reclamation[key]
      }
    }
  }
}
</script>
