import getValuesArray from '@/utils/array/getValuesArray'
import { toNum } from '@/utils/string'

export function addIdTo (formData, keys) {
  for (const key of keys) {
    if (key in formData) {
      const newKey = key + '_id'
      formData[newKey] = formData[key]?.id || null
    }
  }
}

export function addIdsTo (formData, keys) {
  for (const key of keys) {
    if (formData[key]) {
      const newKey = key + '_ids'
      formData[newKey] = getValuesArray(formData[key])
    }
  }
}

export function transformToNumbers (formData, keys) {
  for (const key of keys) {
    if (!formData[key]) continue

    formData[key] = toNum(formData[key])
  }
}
