<template>
  <div>
    <h1 class="mb-xs">Создать рекламацию</h1>

    <div class="box">
      <ReclamationExternalEditForm
        is-create
        @cancel="goToReclamationListPage"
      />
    </div>
  </div>
</template>

<script>
import { ReclamationExternalEditForm } from '@/features/reclamation-external/edit'
import { goToPage } from '@/utils/router'

export default {
  name: 'ReclamationCreatePage',
  components: { ReclamationExternalEditForm },
  methods: {
    goToReclamationListPage () {
      goToPage(this.$store.state.pages.reclamationPages.list)
    }
  }
}
</script>
